<template>
  <b-container class="page-container">
    <bread-crumbs currentpage="Reserveren" />
    <h1>{{ $t('reserve') }}</h1>
    <hr />
    <b-form @submit.prevent="checkout">
      <b-row>
        <b-col md="7" sm="12">
          <h3>{{ $t('information-orderer') }}</h3>

          <b-card class="mt-2">
            <b-row>
              <b-col cols="6" class="pb-3">
                <label>{{ $t('firstname') }}</label>
                <b-form-input
                  v-model="orderer.firstname"
                  disabled
                ></b-form-input>
              </b-col>
              <b-col cols="6" class="pb-3">
                <label>{{ $t('lastname') }}</label>
                <b-form-input
                  v-model="orderer.lastname"
                  disabled
                ></b-form-input>
              </b-col>

              <b-col cols="6" class="pb-3">
                <label>Emailadres</label>
                <b-form-input v-model="orderer.email" disabled></b-form-input>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col md="5" sm="12">
          <h3>{{ $t('overview') }}</h3>
          <b-card
            ><div>
              <strong>{{ $t('retrieval-location') }}</strong>
              <p>{{ cartCache.WarehouseID }}</p>
            </div>
            <div>
              <strong>{{ $t('retrieval-date') }}</strong>
              <p>
                {{ format(cartCache.DateTimeBusinessStart) }}
              </p>
            </div>
            <div>
              <strong>{{ $t('return-date') }}</strong>
              <p>
                {{ format(cartCache.DateTimeExpectedEnd) }}
              </p>
            </div>
          </b-card>
        </b-col>
        <div class="col-12">
          <div class="mt-3">
            <b-table :items="items" :fields="fields" />
          </div>
        </div>
      </b-row>
      <b-card class="mb-4 mt-2">
        <div class="mt-3">
          <label
            >{{ $t('telephonenumber') }}
            <span class="text-danger">*</span></label
          >
          <b-form-input
            v-model="tel"
            type="number"
            pattern="[0-9]*"
            required
          ></b-form-input>
        </div>

        <div
          v-if="$store.getters.account.RoleName === 'key user FDMCI'"
          class="mt-3"
        >
          <label
            >Voucher
            <small
              >Maximaal 20 karakters zonder speciale tekens (1-9, a-Z)</small
            ></label
          >

          <input
            id="exampleInputEmail1"
            v-model="voucher"
            type="text"
            class="form-control"
            pattern="[a-zA-Z0-9]*"
            aria-describedby="voucher"
            placeholder="Voucher"
          />
        </div>

        <hr />
        <b-button class="float-right btn" variant="primary" type="submit"
          >{{ $t('confirm-request') }}&nbsp;<icon-spinner v-if="checkoutLoading"
        /></b-button>
        <h2 class="pt-2">{{ $t('request-to-confirm') }}</h2>
      </b-card>
    </b-form>
  </b-container>
</template>

<script>
import { getCart, checkout } from '@/services/CartService'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import IconSpinner from '@/components/icons/IconSpinner'
dayjs.extend(customParseFormat)

export default {
  components: { IconSpinner },
  data() {
    return {
      voucher: '',
      checkoutLoading: false,
      orderer: {
        firstname: '',
        lastname: '',
        street: '',
        houseNumber: '',
        postal: '',
        city: '',
        email: '',
        tel: ''
      },
      reserverationDetails: {
        eventDescription: '',
        eventStartDate: '',
        eventStartHour: '',
        eventAdres: '',
        expectedRise: '',
        files: '',
        extraInfo: ''
      },
      notes: '',
      tel: '',
      CUST_ActivityDescription: '',
      items: [],
      retrievalDate: '',
      returnDate: '',
      warehouse: '',
      fields: [
        {
          key: 'item',
          label: this.$t('product')
        },
        {
          key: 'cartItemID',
          label: '',
          hidden: true,
          class: 'd-none'
        },
        {
          key: 'amount',
          label: this.$t('amount'),
          class: 'text-center'
        }
      ]
    }
  },
  computed: {
    cartCache: function() {
      return this.$store.getters.cartCache
    },
    subTotal: function() {
      const sumPrice = this.items
        .map(item => item.total)
        .reduce((a, c) => {
          return a + c
        })

      return (Math.round(sumPrice * 100) / 100).toFixed(2).replace('.', ',')
    },
    user: function() {
      return this.$store.getters.user
    },
    account: function() {
      return this.$store.getters.account
    },
    contact: function() {
      return this.user.Contacts.filter(
        x => x.ContactID == this.$store.getters.contactID
      )[0]
    }
  },
  watch: {
    voucher() {
      if (this.voucher.length > 20) {
        // strip everything beyond 20th character from this.voucher
        this.voucher = this.voucher.substring(0, 20)
      }
    }
  },
  async beforeMount() {
    const cart = await getCart()
    this.items = cart

    this.retrievalDate = dayjs(
      this.$store.getters.cartCache.DateTimeBusinessStart
    ).format('DD/MM/YYYY')
    this.returnDate = dayjs(
      this.$store.getters.cartCache.DateTimeBusinessEnd
    ).format('DD/MM/YYYY')
    this.warehouse = this.cartCache.WarehouseID
  },

  created() {
    this.orderer.houseNumber = this.account.CustomerHouseNumber
    this.orderer.city = this.account.CustomerCity
    this.orderer.postal = this.account.CustomerZipCode
    this.orderer.email = this.user.Email
    this.orderer.firstname = this.user.FirstName
    this.orderer.lastname = this.user.LastName
    this.orderer.tel = this.user.Tel
    this.orderer.street = this.account.CustomerStreet
  },

  methods: {
    format(date) {
      return dayjs(date, 'YYYY-MM-DD').format('DD-MM-YYYY')
    },
    checkout: async function() {
      this.checkoutLoading = true

      const reservation = await checkout({
        reserverationDetails: this.reserverationDetails,
        notes: this.notes,
        Voucher: this.voucher || null,
        AddressTel: this.tel,
        CUST_ActivityDescription: this.CUST_ActivityDescription
      })
      reservation.Voucher = this.voucher ?? null

      this.$store.commit('setLatestReservation', {
        reservation
      })
      this.items = await getCart({ notes: this.notes })
      this.$router.push({
        name: 'reservation-confirmation',
        props: { fields: this.fields, reservation },
        params: { lang: this.$i18n.locale }
      })

      this.checkoutLoading = false
    }
  }
}
</script>
<style lang="scss">
.column-total {
  min-width: 80px;
}
</style>
